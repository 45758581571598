import * as Msdyn365 from '@msdyn365-commerce/core';
import * as ProductHandler from '../experlogix-d365commerce-core/eCommerceProductHandler';

/**
 * GetProductExperlogixSeriesModel Input Action
 */
export class GetProductExperlogixSeriesModelInput implements Msdyn365.IActionInput {
    private _experlogixSiteUrl: string;
    private _productItemId: string;

    constructor(experlogixSiteUrl: string, productItemId: string) {
        if (!experlogixSiteUrl) {
            throw new Error(`GetProductExperlogixSeriesModelInput: experlogixSiteUrl must be specified`);
        }

        if (!productItemId) {
            throw new Error(`GetProductExperlogixSeriesModelInput: productItemId must be specified`);
        }

        this._experlogixSiteUrl = experlogixSiteUrl;
        this._productItemId = productItemId;
    }

    public getCacheKey = () => this._productItemId;
    public getCacheObjectType = () => 'GetProductExperlogixSeriesModelData';
    public dataCacheType = (): Msdyn365.CacheType => 'application';

    // Build the URL to the product handler
    public getHandlerUrl = () => ProductHandler.getUrl(this._experlogixSiteUrl);
    public getBody = () => ProductHandler.createBody(this._productItemId);
}

/**
 * Performs a request to the Experlogix handler for eCommerce Products, to retrieve Series/Model constraints
 * for a specified ItemId.
 */
async function action(input: GetProductExperlogixSeriesModelInput, ctx: Msdyn365.IActionContext): Promise<string | null | undefined> {
    const response = await Msdyn365.sendRequest<string>(input.getHandlerUrl(), 'post', input.getBody());

    if (response.status === 200) {
        return response.data || null;
    }

    ctx.telemetry.exception(
        new Error(`GetProductExperlogixSeriesModel: Unexpected result from CPQ handler (${input.getHandlerUrl()}): ${response.data}`)
    );

    return;
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<string>>action,
    id: 'GetProductExperlogixSeriesModel'
});
