import { IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';

/**
 * Utility function to extract the active productId in the following priority:
 * First query param (productId), then UrlToken (itemId), then module config.
 * @param inputData The Action Input data.
 */
export const getSelectedProductIdFromActionInput = (inputData: ICreateActionContext<IGeneric<IAny>>): string | undefined => {
    if (inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.productId) {
        return inputData.requestContext.query.productId;
    } else if (inputData && inputData.requestContext && inputData.requestContext.urlTokens && inputData.requestContext.urlTokens.itemId) {
        return inputData.requestContext.urlTokens.itemId;
    } else if (inputData && inputData.config && inputData.config.productId) {
        return inputData.config.productId;
    }
    return undefined;
};

/**
 * Utility function to extract the active cartLineId in the following priority:
 * First query param (cartLineId), then module config.
 * @param inputData The Action Input data.
 */
export const getSelectedCartLineIdFromActionInput = (inputData: ICreateActionContext<IGeneric<IAny>>): string | undefined => {
    if (inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.cartLineId) {
        return inputData.requestContext.query.cartLineId;
    } else if (inputData && inputData.config && inputData.config.cartLineId) {
        return inputData.config.cartLineId;
    }
    return undefined;
};
